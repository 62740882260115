import {
  DeviationDataView,
  UploadFileButton
} from '@cdab/scania/qpr/components/atoms'
import { AttachmentList } from '@cdab/scania/qpr/components/molecules'
import { useDeviation } from '@cdab/scania/qpr/contexts/deviation'
import { useCssVariableBreakpoint } from '@cdab/scania/qpr/hooks'
import { auditsController } from '@cdab/scania/qpr/offline/controllers'
import type {
  AuditModel,
  DeviationModel
} from '@cdab/scania/qpr/offline/models'
import type { AuditPoint, FileData, UserFile } from '@cdab/scania/qpr/schema'
import { freeMemory } from '@cdab/scania/qpr/utils'
import {
  Card,
  Checkbox,
  Column,
  DelayedSpinner,
  Dropdown,
  DropdownOption,
  EmptyScreen,
  IconWarning,
  Row,
  Spinner
} from '@cdab/scania/sdds'
import { capitalizeFirstLetter, fileIsImage } from '@cdab/utils'
import { TdsDatetime, TdsTextField, TdsTextarea } from '@scania/tegel-react'
import cx from 'classnames'
import { observer } from 'mobx-react-lite'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import invariant from 'tiny-invariant'
import { v4 as uuidv4 } from 'uuid'
import {
  EmptyScreenWrapper,
  FormRow,
  StyledCard
} from './deviation-details.styles'
import type { DeviationDetailsProps } from './deviation-details.types'

type AuditPointNumberData = Pick<AuditPoint, 'auditPointNo'> & {
  auditPointId: AuditPoint['id']
}

type UseAuditState =
  | {
      status: 'success'
      audit: AuditModel
    }
  | {
      status: 'loading'
      audit: undefined
    }

const useAudit = (auditId: number) => {
  const [state, setState] = useState<UseAuditState>({
    status: 'loading',
    audit: undefined
  })

  useEffect(() => {
    async function asyncEffect() {
      const { audit } = await auditsController.GetAudit(auditId)

      setState({
        status: 'success',
        audit
      })
    }
    asyncEffect()
  }, [auditId])

  return state
}

export const DeviationDetails = observer(
  ({
    auditId,
    deviation,
    isReadOnly,
    editMode,
    chatFiles,
    defaultAuditPoint,
    oneColumn = false
  }: Readonly<DeviationDetailsProps>) => {
    const { t } = useTranslation('common')

    const { isGettingFiles, files } = deviation ?? {}
    const auditState = useAudit(auditId)
    const isLg = useCssVariableBreakpoint('--sdds-grid-lg')

    let auditPointNumbers: AuditPointNumberData[] = []

    if (auditState.audit) {
      auditPointNumbers = auditState.audit.auditPoints
        .slice()
        .sort((a, b) => a.sortOrder - b.sortOrder)
        .map(({ auditPointNo, id }) => ({ auditPointNo, auditPointId: id }))
    }

    const ctx = useDeviation()

    let selectedFilesToUpload: UserFile[] = ctx?.form?.watch('files') ?? []

    useEffect(() => {
      if (!editMode && selectedFilesToUpload.length > 0) {
        //clear selected files to upload when not in edit mode
        ctx?.form?.setValue('files', [])
        selectedFilesToUpload = []
      }
    }, [editMode])

    useEffect(() => {
      return () => {
        freeMemory(selectedFilesToUpload)
      }
    }, [])

    const onUploadFilesClick = (fileList: FileList) => {
      const files = Array.from(fileList)

      if (files.length === 0) return

      const inputFiles: UserFile[] = Array.from(files).map(file => ({
        file,
        id: uuidv4(),
        url: URL.createObjectURL(file)
      }))

      const nextFiles = [...selectedFilesToUpload, ...inputFiles]

      ctx?.form?.setValue('files', nextFiles)
    }

    const onDeleteFileClick = (fileId: UserFile['id']) => {
      // TODO: Add translation
      if (!window.confirm('Are you sure you want to delete this file?')) return

      const currentValues = ctx?.form?.getValues('filesToDelete') ?? []
      currentValues.push(fileId)

      ctx?.form?.setValue('filesToDelete', currentValues)
    }

    const onDeleteSelectedFile = (fileId: FileData['id']) => {
      // TODO: Add translation
      if (!window.confirm('Are you sure you want to delete this file?')) return

      const selectedFileIndex = selectedFilesToUpload.findIndex(
        f => f.id === fileId
      )

      if (selectedFileIndex === -1) return

      const nextFiles = selectedFilesToUpload.slice()
      const deletedFiles = nextFiles.splice(selectedFileIndex, 1)

      freeMemory(deletedFiles)

      ctx?.form?.setValue('files', nextFiles)
    }

    const onAuditPointNumberChange = ({
      auditPointId,
      auditPointNo
    }: AuditPointNumberData) => {
      ctx?.form?.setValue('auditPointNumber', auditPointNo)
      ctx?.form?.setValue('auditPointId', auditPointId.toString())
    }

    const filesBeingUploaded: DeviationModel['files'] = []
    const uploadedFiles: DeviationModel['files'] = []
    // NOTE: If we deleted files but didn't apply this action yet, we have to filter these files from everywhere
    files
      ?.filter(
        ({ id }) =>
          (ctx?.form?.watch('filesToDelete') ?? []).includes(id) === false
      )
      .forEach(file => {
        if (file.isUploaded) {
          uploadedFiles.push(file)
        } else {
          filesBeingUploaded.push(file)
        }
      })

    const noFilesPresent =
      selectedFilesToUpload.length === 0 &&
      filesBeingUploaded.length === 0 &&
      uploadedFiles.length === 0 &&
      !isGettingFiles

    function DeviationEditForm() {
      if (!auditState.audit || auditState.audit.isReadonly) return null

      return (
        <div>
          <Checkbox
            {...ctx?.form?.register('withActionPlan')}
            label={t('deviation-without-action-plan', { ns: 'audit' })}
            checked={!ctx?.form?.watch('withActionPlan')}
            onChange={e =>
              ctx?.form?.setValue(
                'withActionPlan',
                !ctx?.form?.getValues('withActionPlan')
              )
            }
          />
          {!defaultAuditPoint && (
            <FormRow>
              <Dropdown
                {...ctx?.form?.register('auditPointId')}
                onWhite
                size='sm'
                label={capitalizeFirstLetter(t('audit-point'))}
                placeholder={t('select-audit-point', { ns: 'audit' })}
                disabled={auditState.audit.isReadonly}
                onSelect={e => {
                  const auditPointId = Number(e.value)
                  const auditPointNo = auditPointNumbers.find(
                    ap => ap.auditPointId === auditPointId
                  )?.auditPointNo
                  invariant(auditPointNo)

                  onAuditPointNumberChange({ auditPointId, auditPointNo })
                }}
                defaultOption={ctx?.form?.watch('auditPointId')}
                helper={ctx?.form?.formState.errors[
                  'auditPointId'
                ]?.message?.toString()}
                state={ctx?.form?.formState.errors['auditPointId'] && 'error'}
              >
                {auditPointNumbers.map(({ auditPointId, auditPointNo }) => (
                  <DropdownOption
                    key={auditPointId}
                    value={auditPointId?.toString() || ''}
                    text={auditPointNo.toString()}
                  />
                ))}
              </Dropdown>
            </FormRow>
          )}
          <FormRow>
            <TdsTextarea
              {...ctx?.form?.register('deviation')}
              cols={2}
              helper={ctx?.form?.formState.errors[
                'deviation'
              ]?.message?.toString()}
              maxLength={1000}
              name='deviation'
              label={`${capitalizeFirstLetter(t('deviation'))}*`}
              labelPosition='outside'
              onTdsChange={e =>
                ctx?.form?.setValue('deviation', e.target.value)
              }
              placeholder={t('deviation')}
              state={ctx?.form?.formState.errors['deviation'] && 'error'}
              value={ctx?.form?.getValues('deviation') ?? ''}
            />
          </FormRow>
          {ctx?.form?.watch('withActionPlan') && (
            <FormRow>
              <TdsTextarea
                {...ctx?.form?.register('action')}
                cols={2}
                helper={ctx?.form?.formState.errors[
                  'action'
                ]?.message?.toString()}
                maxLength={1000}
                label={`${capitalizeFirstLetter(
                  t('proposed-action', { ns: 'audit' })
                )}*`}
                labelPosition='outside'
                name='action'
                onTdsChange={e => ctx?.form?.setValue('action', e.target.value)}
                placeholder={t('proposed-action', { ns: 'audit' })}
                state={ctx?.form?.formState.errors['action'] && 'error'}
                value={ctx?.form?.getValues('action') ?? ''}
              />
            </FormRow>
          )}
          {ctx?.form?.getValues('withActionPlan') && (
            <FormRow>
              <TdsTextField
                {...ctx?.form?.register('assignee')}
                helper={ctx?.form?.formState.errors[
                  'assignee'
                ]?.message?.toString()}
                size='sm'
                maxLength={100}
                name='assignee'
                label={`${capitalizeFirstLetter(t('responsible'))}*`}
                labelPosition='outside'
                onTdsChange={e =>
                  ctx?.form?.setValue('assignee', e.target.value)
                }
                placeholder={t('responsible', { ns: 'audit' })}
                state={ctx?.form?.formState.errors['assignee'] && 'error'}
                value={ctx?.form?.getValues('assignee')}
              />
            </FormRow>
          )}
          {ctx?.form?.getValues('withActionPlan') && (
            <FormRow>
              <TdsDatetime
                {...ctx?.form?.register('due')}
                min={undefined}
                max={undefined}
                helper={ctx?.form?.formState.errors['due']?.message?.toString()}
                size='sm'
                name='dueDate'
                label={`${capitalizeFirstLetter(t('due-date'))}*`}
                noMinWidth={true}
                type='date'
                onTdsChange={e => ctx?.form?.setValue('due', e.target.value)}
                state={ctx?.form?.formState.errors['due'] && 'error'}
                value={ctx?.form?.getValues('due')}
              />
            </FormRow>
          )}
          {ctx?.form?.getValues('withActionPlan') && (
            <FormRow>
              <TdsDatetime
                {...ctx?.form?.register('completed')}
                min={undefined}
                max={undefined}
                helper={ctx?.form?.formState.errors[
                  'completed'
                ]?.message?.toString()}
                size='sm'
                type='date'
                name='completed'
                label={capitalizeFirstLetter(t('completed'))}
                noMinWidth={true}
                onTdsChange={e =>
                  ctx?.form?.setValue('completed', e.target.value)
                }
                state={ctx?.form?.formState.errors['completed'] && 'error'}
                value={ctx?.form?.getValues('completed')}
              />
            </FormRow>
          )}
        </div>
      )
    }

    function DeviationEvidenceCardContent() {
      return (
        <>
          {editMode && !noFilesPresent && (
            <UploadFileButton size='sm' onUploadFiles={onUploadFilesClick} />
          )}
          <div>
            {uploadedFiles.length > 0 && (
              <AttachmentList
                color='on-white'
                files={uploadedFiles}
                onDeleteAttachment={onDeleteFileClick}
                disableDelete={!editMode || isReadOnly}
              />
            )}
            {isGettingFiles && <Spinner />}
          </div>

          {selectedFilesToUpload.length > 0 && (
            <AttachmentList
              color='on-white'
              fileType='all'
              files={selectedFilesToUpload.map(({ file, id, url }) => ({
                fileName: file.name,
                id,
                isImage: fileIsImage(file),
                isUploaded: false,
                uploadProgress: 0,
                url
              }))}
              hideUploadProgress
              onDeleteAttachment={onDeleteSelectedFile}
              disableDelete={isReadOnly}
              title='Selected files'
            />
          )}
          {filesBeingUploaded.length > 0 && (
            <div>
              <AttachmentList
                color='on-white'
                files={filesBeingUploaded}
                disableDelete
                title={t('files-not-yet-uploaded')}
              />
            </div>
          )}

          {noFilesPresent && (
            <EmptyScreenWrapper>
              <EmptyScreen
                title={capitalizeFirstLetter(t('no-evidence-exists'))}
                description={capitalizeFirstLetter(t('no-uploaded-files'))}
                icon={<IconWarning />}
                callToAction={
                  editMode
                    ? {
                        type: 'button',
                        component: (
                          <UploadFileButton
                            text={t('add-evidence', { ns: 'audit' })}
                            size='sm'
                            onUploadFiles={onUploadFilesClick}
                          />
                        )
                      }
                    : undefined
                }
              />
            </EmptyScreenWrapper>
          )}
        </>
      )
    }

    if (auditState.status === 'loading') return <DelayedSpinner />

    return (
      <Row>
        <Column width={12} lg={oneColumn ? 12 : 6} padding={false}>
          <Row>
            <StyledCard headline='Details'>
              {editMode ? (
                <DeviationEditForm />
              ) : (
                <DeviationDataView
                  auditPointNumber={deviation?.auditPointNumber}
                  deviation={deviation?.deviation}
                  expirationDate={deviation?.expirationDate}
                  proposedActions={deviation?.proposedActions}
                  responsible={deviation?.responsible}
                  approvalDate={deviation?.approvalDate}
                  withActionPlan={deviation?.deviationWithoutActions === false}
                  defaultAuditPoint={defaultAuditPoint}
                />
              )}
            </StyledCard>
          </Row>
          {oneColumn && (
            <Row>
              <StyledCard headline='Evidence' className='sdds-u-mt3'>
                <DeviationEvidenceCardContent />
              </StyledCard>
            </Row>
          )}
          {chatFiles.length > 0 && (
            <Row>
              <StyledCard
                headline='Chat files'
                subheadline='All files uploaded in the chat are listed here'
                className='sdds-u-mt3'
              >
                <AttachmentList
                  color='on-white'
                  hideUploadProgress
                  fileType='all'
                  files={chatFiles}
                  disableDelete={true}
                />
              </StyledCard>
            </Row>
          )}
        </Column>
        {!oneColumn && (
          <Column
            width={12}
            lg={6}
            padding={isLg}
            style={{ paddingRight: '0' }}
          >
            <Card headline='Evidence' className={cx({ 'sdds-u-mt3': !isLg })}>
              <DeviationEvidenceCardContent />
            </Card>
          </Column>
        )}
      </Row>
    )
  }
)
