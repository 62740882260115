import React, { forwardRef, useCallback, useEffect, useState } from 'react'

import { useLongPress } from '@cdab/scania/qpr/hooks'
import { getNextAuditItemState } from './audit-item.internals'
import {
  ChildrenWrapper,
  Content,
  ContentWrapper,
  getAuditItemStateIcon,
  IconButton,
  PrefixWrapper,
  RightItem,
  RightSideArrow,
  StyledAuditItem,
  StyledIconInfo
} from './audit-item.styles'
import type { AuditItemProps } from './audit-item.types'
import { ArrowType, AuditItemState, AuditItemType } from './audit-item.types'

export const AuditItem = forwardRef<HTMLDivElement, AuditItemProps>(
  function AuditItem(
    {
      children,
      prefix,
      value,
      hideButton,
      onChange,
      defaultValue = AuditItemState.NotSet,
      id,
      onClick,
      className,
      type = AuditItemType.Normal,
      fullWidth,
      isMandatory,
      arrowType = ArrowType.Hidden,
      maxLines = 2,
      disableNo = false,
      disabled = false,
      rightItem,
      fullChildren,
      isBottomNavigationItem = false
    }: AuditItemProps,
    ref
  ) {
    const [itemState, setItemState] = useState<AuditItemState>(defaultValue)
    const { isLongPressState, handlers } = useLongPress(2000)
    const onButtonClick = useCallback(
      (e: React.MouseEvent) => {
        e.stopPropagation() // don't trigger `onClick` when clicking the button

        const nextVal = getNextAuditItemState(value ?? itemState, disableNo)

        if (onChange) {
          onChange(nextVal)
        }

        // Don't change internal state if value is used
        if (!value) setItemState(nextVal)
      },
      [itemState, onChange, value, disableNo]
    )

    const onClickHandle = useCallback(
      (e: React.MouseEvent) => {
        if (onClick) onClick(e, isLongPressState)
      },
      [isLongPressState, onClick]
    )

    const onContextMenu = useCallback(
      (e: React.MouseEvent<Element, MouseEvent>) => {
        if (isBottomNavigationItem && isLongPressState && onClick) {
          e.preventDefault()
          onClick(e, isLongPressState)
        }
      },
      [isBottomNavigationItem, isLongPressState, onClick]
    )

    useEffect(() => {
      // Keep internal state up to date with value
      if (value) setItemState(value)
    }, [value])

    // Use value if it is provided
    const state = value ?? itemState
    const icon = getAuditItemStateIcon(state)

    return (
      <StyledAuditItem
        ref={ref}
        className={className}
        fullWidth={!!fullWidth}
        id={id}
        onClick={onClickHandle}
        role='listitem'
        state={state}
        type={type}
        onContextMenu={onContextMenu}
        {...handlers}
      >
        <ChildrenWrapper>
          {!hideButton && (
            <IconButton
              onClick={onButtonClick}
              state={state}
              disabled={disabled}
            >
              {icon}
            </IconButton>
          )}
          <ContentWrapper>
            {prefix && <PrefixWrapper type={type}>{prefix}</PrefixWrapper>}
            {isMandatory && <StyledIconInfo />}
            <Content maxLines={maxLines} type={type}>
              {children}
            </Content>
          </ContentWrapper>
          {rightItem && <RightItem>{rightItem}</RightItem>}
          {arrowType !== ArrowType.Hidden && (
            <RightSideArrow arrowType={arrowType} />
          )}
        </ChildrenWrapper>
        {fullChildren}
      </StyledAuditItem>
    )
  }
)
