export type MergeFunc<
  T extends Record<string, unknown> = Record<string, unknown>
> = (target: T, source: T, keysToExclude?: string[]) => void

export const mergeObjectFields: MergeFunc = (
  target,
  source,
  keysToExclude: string[] = []
) => {
  Object.keys(source).forEach(key => {
    if (keysToExclude.includes(key)) return

    const value = source[key]

    // Arrays are considered objects in JS, i.e. typeof [] === 'object', so they are skipped as well
    if (typeof value === 'object' && value !== null) {
      return
    }

    target[key] = value
  })
}

export function addOrMergeArrayObject<T extends Record<string, unknown>>(
  array: T[],
  toAdd: T,
  findPredicate: (t: T) => boolean,
  mergeFunc: MergeFunc<T> = mergeObjectFields
) {
  const index = array.findIndex(findPredicate)

  if (index === -1) {
    array.push(toAdd)
  } else {
    mergeFunc(array[index], toAdd)
  }
}
