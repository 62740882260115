import {
  CenteredContainer,
  ElasticContentContainer,
  IconButton
} from '@cdab/scania/qpr/components'
import { getClient } from '@cdab/scania/qpr/contexts/backend-provider'
import { useTitle } from '@cdab/scania/qpr/contexts/title'
import {
  useCssVariableBreakpoint,
  useSddsTableRowClickWithCellValue
} from '@cdab/scania/qpr/hooks'
import {
  Column,
  Container,
  DataTable,
  EmptyScreen,
  HeaderCell,
  IconWarning,
  Row
} from '@cdab/scania/sdds'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import {
  isRouteErrorResponse,
  json,
  useLoaderData,
  useNavigate,
  useRouteError
} from 'react-router'
import { StyledIcon } from './documents.styles'
import type { AdminReferenceDocument } from '@cdab/scania/qpr/schema'
import { Roles } from '@cdab/scania/qpr/schema'
import { capitalizeAllFirstLetters, formatISODate } from '@cdab/utils'

export type AdminDocumentsLoaderData = {
  documents: AdminReferenceDocument[]
}

export async function loader(): Promise<AdminDocumentsLoaderData> {
  const client = getClient()

  const userInfo = await client.GetuserInfo()
  if (!userInfo) {
    throw json({
      status: 401
    })
  }

  const user = await client.UserService.GetUser(userInfo.userId)
  if (user.role !== Roles.FactoryAdmin) {
    throw json({
      status: 403
    })
  }

  try {
    const [documents] = await Promise.all([
      client.DocumentService.GetDocuments()
    ])

    return {
      documents
    }
  } catch (error) {
    if (error instanceof Response) {
      throw new Response(null, {
        status: error.status
      })
    } else {
      throw new Response(null, {
        status: 404
      })
    }
  }
}

function prepareTableData(data: AdminReferenceDocument[]) {
  const preparedData = data
    .sort((a: AdminReferenceDocument, b: AdminReferenceDocument) =>
      b.updatedTime.localeCompare(a.updatedTime)
    )
    .map(document => {
      return {
        market: document.market ? document.market.marketName : 'Factory',
        logicalFileName: document.title,
        physicalFileName: document.physicalDocumentFileName ?? document.url,
        auditPoints: document.auditPoints
          .map(auditPoint => auditPoint.auditPointNo)
          .join(', '),
        checkpoints: document.checkPoints
          .map(checkPoint => checkPoint.checkPointNo)
          .join(', '),
        updatedTime: formatISODate(new Date(document.updatedTime)),
        documentId: document.id
      }
    })

  return preparedData
}

export function Page() {
  const navigate = useNavigate()
  const { t } = useTranslation(['common', 'documents'])
  const { updateTitles } = useTitle()
  const isLg = useCssVariableBreakpoint('--sdds-grid-lg')
  const { documents } = useLoaderData() as AdminDocumentsLoaderData
  const [tableEl, setTableEl] = useState<HTMLTableRowElement>()

  useSddsTableRowClickWithCellValue(tableEl, 'documentId', documentId => {
    navigate(`/admin/documents/${documentId}`)
  })

  useEffect(() => {
    updateTitles({
      contentHeader: {
        subtitle: capitalizeAllFirstLetters(
          t('reference-documents', { ns: 'common' })
        ),
        title: t('administration')
      },
      mobile: {
        title: `${capitalizeAllFirstLetters(
          t('reference-documents', { ns: 'common' })
        )}`,
        description: null
      }
    })
  }, [t])

  return (
    <ElasticContentContainer scrollY overflowHidden>
      <Container fluid='normal'>
        <Row>
          <Column width={12} padding={isLg}>
            <IconButton
              disabled={false}
              onClick={() => navigate('/admin/documents/new')}
            >
              <span>{t('add-new')}</span>
              <StyledIcon />
            </IconButton>
          </Column>
          <Column width={12} padding={isLg} scrollX>
            <DataTable
              data={prepareTableData(documents)}
              ref={setTableEl}
              filtering
            >
              <HeaderCell columnKey='market' title={t('market')} sortable />
              <HeaderCell
                columnKey='logicalFileName'
                title={t('logical-file-name', { ns: 'documents' })}
                sortable
              />
              <HeaderCell
                columnKey='physicalFileName'
                title={t('physical-file-name', { ns: 'documents' })}
                sortable
              />
              <HeaderCell
                columnKey='auditPoints'
                title={t('audit-points')}
                sortable
              />
              <HeaderCell
                columnKey='checkPoints'
                title={t('checkpoints')}
                sortable
              />
              <HeaderCell
                columnKey='updatedTime'
                title={t('updated-date', { ns: 'documents' })}
                sortable
              />
              <HeaderCell columnKey='documentId' title={'ID'} sortable />
            </DataTable>
          </Column>
        </Row>
      </Container>
    </ElasticContentContainer>
  )
}

export function ErrorBoundary() {
  const { t } = useTranslation('errors')
  const isLg = useCssVariableBreakpoint('--sdds-grid-lg')
  const error = useRouteError()

  if (isRouteErrorResponse(error)) {
    let message = t('talk-to-someone')

    if (error.status === 403) {
      message = t('access-denied')
    }

    return (
      <CenteredContainer
        fluid='normal'
        paddingOnColumns={!isLg}
        paddingOnContainer={!isLg}
      >
        <Row>
          <Column fullHeight width={12} padding={false}>
            <EmptyScreen
              title={t('could-not-load-page')}
              description={message}
              icon={<IconWarning />}
            />
          </Column>
        </Row>
      </CenteredContainer>
    )
  }

  // rethrow to let the parent error boundary handle it
  // when it's not a special case for this route
  throw error
}
