import { useTranslation } from 'react-i18next'
import {
  isRouteErrorResponse,
  json,
  useLoaderData,
  useRouteError
} from 'react-router'
import { useTitle } from '@cdab/scania/qpr/contexts/title'
import { useEffect } from 'react'
import {
  CenteredContainer,
  DocumentCreateForm,
  ScrollableWrapper
} from '@cdab/scania/qpr/components'
import { getClient } from '@cdab/scania/qpr/contexts/backend-provider'
import { useCssVariableBreakpoint } from '@cdab/scania/qpr/hooks'
import {
  Roles,
  type AuditPointNumber,
  type CheckPointNumber,
  type Market
} from '@cdab/scania/qpr/schema'
import {
  Breadcrumbs,
  Column,
  Container,
  EmptyScreen,
  IconWarning,
  Row
} from '@cdab/scania/sdds'
import { capitalizeAllFirstLetters } from '@cdab/utils'

//Temp solution, since we are using only one version now
//Later we can use it if we will add supporting of diff DOS versions
const CurrentDOSVersionId = 6 // DOS 5

export type NewAdminDocumentLoaderData = {
  auditPoints: AuditPointNumber[]
  checkPoints: CheckPointNumber[]
  allMarkets: Market[]
}

export async function loader(): Promise<NewAdminDocumentLoaderData> {
  try {
    const client = getClient()
    const userInfo = await client.GetuserInfo()
    if (!userInfo) {
      throw json({
        status: 401
      })
    }

    const [currentUser, auditPoints, checkPoints, allMarkets] =
      await Promise.all([
        client.UserService.GetUser(userInfo.userId),
        client.AuditsService.GetAuditPointNumbers(CurrentDOSVersionId),
        client.AuditsService.GetCheckPointNumbers(CurrentDOSVersionId),
        client.MarketsService.GetAllMarkets()
      ])

    if (currentUser.role < Roles.FactoryEngineAdmin) {
      throw json({
        status: 403
      })
    }

    return {
      auditPoints,
      checkPoints,
      allMarkets
    }
  } catch (error) {
    if (error instanceof Response) {
      throw new Response(null, {
        status: error.status
      })
    } else {
      throw new Response(null, {
        status: 404
      })
    }
  }
}

export function Page() {
  const { t } = useTranslation(['documents', 'common'])
  const isLg = useCssVariableBreakpoint('--sdds-grid-lg')
  const { auditPoints, checkPoints, allMarkets } =
    useLoaderData() as NewAdminDocumentLoaderData
  const { updateTitles } = useTitle()

  useEffect(() => {
    updateTitles({
      contentHeader: {
        subtitle: capitalizeAllFirstLetters(
          t('reference-documents', { ns: 'common' })
        ),
        title: t('administration', { ns: 'common' })
      },
      mobile: {
        title: `${capitalizeAllFirstLetters(
          t('reference-documents', { ns: 'common' })
        )}`,
        description: null
      }
    })
  }, [t])

  return (
    <ScrollableWrapper>
      <Container
        fullHeight
        fluid='normal'
        paddingOnColumns={isLg}
        paddingOnContainer={isLg}
      >
        <Row>
          <Column width={12} className='sdds-u-pt2'>
            <Breadcrumbs
              links={[
                {
                  text: capitalizeAllFirstLetters(
                    t('reference-documents', { ns: 'common' })
                  ),
                  to: `/admin/documents/all`
                },
                { text: t('new', { ns: 'common' }), to: '' }
              ]}
            />
          </Column>
        </Row>
        <Row>
          <DocumentCreateForm
            auditPoints={auditPoints}
            checkPoints={checkPoints}
            allMarkets={allMarkets}
          />
        </Row>
      </Container>
    </ScrollableWrapper>
  )
}

export function ErrorBoundary() {
  const { t } = useTranslation('errors')
  const isLg = useCssVariableBreakpoint('--sdds-grid-lg')
  const error = useRouteError()

  if (isRouteErrorResponse(error)) {
    let message = t('talk-to-someone')

    if (error.status === 403) {
      message = t('access-denied')
    }

    return (
      <CenteredContainer
        fluid='normal'
        paddingOnColumns={!isLg}
        paddingOnContainer={!isLg}
      >
        <Row>
          <Column fullHeight width={12} padding={false}>
            <EmptyScreen
              title={t('could-not-load-page')}
              description={message}
              icon={<IconWarning />}
            />
          </Column>
        </Row>
      </CenteredContainer>
    )
  }

  // rethrow to let the parent error boundary handle it
  // when it's not a special case for this route
  throw error
}
