import classnames from 'classnames'
import { useTranslation } from 'react-i18next'

import { useFeatures } from '@cdab/scania/qpr/feature-flags'
import { AppLauncherItem } from './app-launcher-item'
import { getIconSize, IconBento } from '@cdab/scania/sdds'
import styled from 'styled-components'

const StyledIconBento = styled(IconBento)`
  width: ${getIconSize('xlarge')};
  height: ${getIconSize('xlarge')};
`

export interface AppLauncherProps {
  onAppLauncherClick: () => void
  className?: string
}

function useAppLinks(): JSX.Element[] {
  const features = useFeatures([
    'release_notes',
    'show_qpr_quick_guide',
    'show_qpr_launch_webinar',
    'show_qpr_news_standards_commercial'
  ])
  const { t } = useTranslation()

  const appLinks = []

  if (features.release_notes.enabled) {
    appLinks.push(
      <AppLauncherItem
        key='release-notes'
        url={features.release_notes.value.release_notes_url}
        text={t('release-notes')}
      />
    )

    appLinks.push(
      <AppLauncherItem
        key='known-bugs'
        url={features.release_notes.value.known_bugs_url}
        text={t('known-bugs')}
      />
    )
  }

  if (features.show_qpr_launch_webinar.enabled) {
    appLinks.push(
      <AppLauncherItem
        key='qpr-launch-webinar'
        text={features.show_qpr_launch_webinar.value.text}
        url={features.show_qpr_launch_webinar.value.url}
      />
    )
  }

  if (features.show_qpr_quick_guide.enabled) {
    appLinks.push(
      <AppLauncherItem
        key='qpr-quick-guide'
        text={features.show_qpr_quick_guide.value.text}
        url={features.show_qpr_quick_guide.value.url}
      />
    )
  }

  if (features.show_qpr_news_standards_commercial.enabled) {
    appLinks.push(
      <AppLauncherItem
        key='show_qpr_news_standards_commercial'
        text={features.show_qpr_news_standards_commercial.value.text}
        url={features.show_qpr_news_standards_commercial.value.url}
      />
    )
  }

  return appLinks
}

export function AppLauncher(props: AppLauncherProps) {
  const { onAppLauncherClick, className } = props
  const appLinks = useAppLinks()
  const { t } = useTranslation('common')

  // It's ugly to display an empty list of app links, so don't show anything instead
  if (appLinks.length === 0) {
    return null
  }

  return (
    <div
      className={classnames('sdds-nav__item sdds-nav__app-launcher', className)}
    >
      <button
        title={t('app-launcher-name')}
        className='sdds-nav__app-launcher-btn'
        onClick={onAppLauncherClick}
      >
        <StyledIconBento />
      </button>
      <ul className='sdds-nav__app-launcher-menu'>{appLinks}</ul>
    </div>
  )
}
