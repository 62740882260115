import { useUser } from '@cdab/scania/qpr/contexts/user-provider'
import type { CreateADHLinkData } from '@cdab/scania/qpr/interactor'
import type { Deviation } from '@cdab/scania/qpr/schema'
import { sortFnByAuditPointNo } from '@cdab/scania/qpr/utils'
import { Divider } from '@cdab/scania/sdds'
import {
  checkStringLength,
  formatISODate,
  removeHtmlTagsFromString
} from '@cdab/utils'
import {
  TdsButton,
  TdsCheckbox,
  TdsMessage,
  TdsModal,
  TdsTextField
} from '@scania/tegel-react'
import { observer } from 'mobx-react-lite'
import { Fragment, useState } from 'react'
import { useTranslation } from 'react-i18next'
import {
  StyledDeviationsList,
  StyledDiv,
  StyledMessageWrapper,
  StyledModalContent,
  StyledParagraph
} from './adh-link-create-form.styles'
import type { ADHLinkCreateFormProps } from './adh-link-create-form.types'

const DEFAULT_LANGUAGE_ID = 2

const filterDeviations = (deviations: Deviation[]) => {
  return deviations
    .filter(deviation => deviation.id !== undefined)
    .filter(deviation => deviation.deviationWithoutActions === false)
}

export const ADHLinkCreateForm = observer(
  ({
    selector,
    onSubmit,
    audit,
    links
  }: Readonly<ADHLinkCreateFormProps>): JSX.Element => {
    const { t } = useTranslation(['audit', 'errors'])
    const userContext = useUser()

    const [stateName, setStateName] = useState<boolean>(true)
    const [stateActionPlans, setStateActionPlans] = useState<boolean>(true)

    const defaultData = {
      key: '',
      name: '',
      languageId: userContext.userData.languageId ?? DEFAULT_LANGUAGE_ID,
      auditId: audit.id,
      did: audit.did,
      expiryDate: '',
      actionPlanItems: []
    }

    const [data, setData] = useState<CreateADHLinkData>(defaultData)

    const validateAllData = () => {
      setStateName(checkStringLength(data.name, 3, false))
      setStateActionPlans(data.actionPlanItems.length > 0)
    }

    const closeModal = () => {
      ;(
        document.querySelector(
          `[selector="${selector}"]`
        ) as HTMLTdsModalElement
      ).closeModal()
    }

    const handleSubmit = () => {
      validateAllData()
      if (
        checkStringLength(data.name, 3, false) &&
        data.actionPlanItems.length > 0
      ) {
        onSubmit(data)
        setData(defaultData)
        closeModal()
      }
    }

    const deviations = filterDeviations(audit.deviations).sort((a, b) =>
      sortFnByAuditPointNo(
        { auditPointNo: a.auditPointNumber },
        { auditPointNo: b.auditPointNumber }
      )
    )

    const usedAuditPoints = deviations.filter(deviation => {
      return links.some(link =>
        link.actionPlanItems.some(
          item => item.actionPlanItemId === deviation?.id
        )
      )
    })

    type GroupedDeviations = {
      [key: string]: typeof deviations
    }

    const unusedAuditPoints = deviations.filter(deviation => {
      return !links.some(link =>
        link.actionPlanItems.some(
          item => item.actionPlanItemId === deviation?.id
        )
      )
    })

    const groupedDeviationsUsedAuditPoints: GroupedDeviations =
      usedAuditPoints.reduce((acc, deviation) => {
        const auditPointId = deviation.auditPointId ?? 'Unknown Audit Point'
        if (!acc[auditPointId]) {
          acc[auditPointId] = []
        }
        acc[auditPointId].push(deviation)
        return acc
      }, {} as GroupedDeviations)

    const groupedDeviationsUnUsedAuditPoints: GroupedDeviations =
      unusedAuditPoints.reduce((acc, deviation) => {
        const auditPointId = deviation.auditPointId ?? 'Unknown Audit Point'
        if (!acc[auditPointId]) {
          acc[auditPointId] = []
        }
        acc[auditPointId].push(deviation)
        return acc
      }, {} as GroupedDeviations)

    const selectOnlyOpenActionPlans = () => {
      const openActionPlans = deviations.filter(
        deviation => deviation.approvalDate === null
      )

      setData({
        ...data,
        actionPlanItems: openActionPlans.map(deviation => ({
          auditId: audit.id,
          actionPlanItemId: deviation.id ?? 0,
          actionPlanId: deviation.actionPlanId
        }))
      })
    }

    return (
      <TdsModal
        header={t('create-link', { ns: 'common' })}
        selector={selector}
        size='lg'
        actions-position='static'
      >
        <>
          <span slot='body'>
            <TdsTextField
              labelPosition='outside'
              label={t('name', { ns: 'common' })}
              placeholder={t('link-name', { ns: 'common' })}
              onTdsChange={e => {
                setData({ ...data, name: e.target.value })
              }}
              onBlur={e => setStateName(checkStringLength(e.target.value, 3))}
              state={!stateName ? 'error' : undefined}
              helper={
                !stateName
                  ? t('validation-error', { ns: 'errors', chars: '3' })
                  : undefined
              }
              value={data.name}
            />
            <StyledModalContent>
              <p className='tds-detail-04'>{t('select-deviations-for-link')}</p>
              <Divider type='light' className='min-u-mb1' />
              <StyledDeviationsList>
                {Object.keys(groupedDeviationsUnUsedAuditPoints).map(
                  auditPointId => (
                    <div key={auditPointId}>
                      <div style={{ display: 'flex', gap: '1rem' }}>
                        <b>
                          {
                            audit.auditPoints.find(
                              ap => ap.id === parseInt(auditPointId, 10)
                            )?.auditPointNo
                          }
                        </b>
                        <span>
                          {removeHtmlTagsFromString(
                            t(
                              audit.auditPoints.find(
                                ap => ap.id === parseInt(auditPointId, 10)
                              )?.translationGuid ?? ''
                            )
                          )}
                        </span>
                      </div>
                      {groupedDeviationsUnUsedAuditPoints[auditPointId].map(
                        deviation => {
                          return (
                            <Fragment key={deviation.id}>
                              <li>
                                <StyledDiv>
                                  <TdsCheckbox
                                    id={`deviation-${deviation.id}`}
                                    checked={
                                      !!data.actionPlanItems?.find(
                                        api =>
                                          api.actionPlanItemId === deviation.id
                                      )
                                    }
                                    onTdsChange={() => {
                                      const api = data.actionPlanItems?.find(
                                        api =>
                                          api.actionPlanItemId === deviation.id
                                      )

                                      if (api) {
                                        setData({
                                          ...data,
                                          actionPlanItems:
                                            data.actionPlanItems?.filter(
                                              api =>
                                                api.actionPlanItemId !==
                                                deviation.id
                                            )
                                        })
                                      } else {
                                        const newActionPlans = [
                                          ...(data.actionPlanItems || [])
                                        ]
                                        newActionPlans.push({
                                          auditId: audit.id,
                                          actionPlanItemId: deviation.id ?? 0,
                                          actionPlanId: deviation.actionPlanId
                                        })

                                        setData({
                                          ...data,
                                          actionPlanItems: newActionPlans
                                        })
                                      }
                                    }}
                                  >
                                    <div slot='label'>
                                      <StyledParagraph>
                                        {deviation.deviation}
                                        {deviation.expirationDate
                                          ? ` ${t(
                                              'expiry-date'
                                            )}: ${formatISODate(
                                              new Date(
                                                deviation.expirationDate ?? ''
                                              )
                                            )}`
                                          : ''}
                                      </StyledParagraph>
                                      <StyledParagraph>
                                        {deviation.proposedActions ?? ''}
                                      </StyledParagraph>
                                    </div>
                                  </TdsCheckbox>
                                </StyledDiv>
                              </li>
                            </Fragment>
                          )
                        }
                      )}
                    </div>
                  )
                )}
                {Object.keys(groupedDeviationsUsedAuditPoints).map(
                  auditPointId => (
                    <div key={auditPointId}>
                      <div style={{ display: 'flex', gap: '1rem' }}>
                        <b>
                          {
                            audit.auditPoints.find(
                              ap => ap.id === parseInt(auditPointId, 10)
                            )?.auditPointNo
                          }
                        </b>
                        <span>
                          {removeHtmlTagsFromString(
                            t(
                              audit.auditPoints.find(
                                ap => ap.id === parseInt(auditPointId, 10)
                              )?.translationGuid ?? ''
                            )
                          )}
                        </span>
                      </div>
                      {groupedDeviationsUsedAuditPoints[auditPointId].map(
                        deviation => {
                          return (
                            <Fragment key={deviation.id}>
                              <li>
                                <StyledDiv>
                                  <TdsCheckbox
                                    id={`deviation-${deviation.id}`}
                                    checked={
                                      !!data.actionPlanItems?.find(
                                        api =>
                                          api.actionPlanItemId === deviation.id
                                      )
                                    }
                                    onTdsChange={() => {
                                      const api = data.actionPlanItems?.find(
                                        api =>
                                          api.actionPlanItemId === deviation.id
                                      )

                                      if (api) {
                                        setData({
                                          ...data,
                                          actionPlanItems:
                                            data.actionPlanItems?.filter(
                                              api =>
                                                api.actionPlanItemId !==
                                                deviation.id
                                            )
                                        })
                                      } else {
                                        const newActionPlans = [
                                          ...(data.actionPlanItems || [])
                                        ]
                                        newActionPlans.push({
                                          auditId: audit.id,
                                          actionPlanItemId: deviation.id ?? 0,
                                          actionPlanId: deviation.actionPlanId
                                        })

                                        setData({
                                          ...data,
                                          actionPlanItems: newActionPlans
                                        })
                                      }
                                    }}
                                  >
                                    <div slot='label'>
                                      <StyledParagraph>
                                        {deviation.deviation}
                                        {deviation.expirationDate
                                          ? ` ${t(
                                              'expiry-date'
                                            )}: ${formatISODate(
                                              new Date(
                                                deviation.expirationDate ?? ''
                                              )
                                            )}`
                                          : ''}
                                      </StyledParagraph>
                                      <StyledParagraph>
                                        {deviation.proposedActions ?? ''}
                                      </StyledParagraph>
                                    </div>
                                  </TdsCheckbox>
                                </StyledDiv>
                              </li>
                            </Fragment>
                          )
                        }
                      )}
                    </div>
                  )
                )}
              </StyledDeviationsList>
              {!stateActionPlans && (
                <StyledMessageWrapper>
                  <TdsMessage
                    className='sdds-u-mt1'
                    variant='error'
                    modeVariant={'primary'}
                  >
                    {t('select-audit-point')}
                  </TdsMessage>
                </StyledMessageWrapper>
              )}
            </StyledModalContent>
          </span>
          <span slot='actions' className='tds-u-flex tds-u-gap2'>
            <TdsButton size='sm' text='Create' onClick={() => handleSubmit()} />
            <TdsButton
              size='sm'
              text='Cancel'
              variant='secondary'
              onClick={() => closeModal()}
            />
            <TdsButton
              size='sm'
              text={t('select-only-open-action-plans')}
              variant='secondary'
              onClick={selectOnlyOpenActionPlans}
            />
          </span>
        </>
      </TdsModal>
    )
  }
)
