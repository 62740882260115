import {
  CenteredContainer,
  ElasticContentContainer,
  ScrollableWrapper
} from '@cdab/scania/qpr/components'
import { getClient } from '@cdab/scania/qpr/contexts/backend-provider'
import { useTitle } from '@cdab/scania/qpr/contexts/title'
import {
  useCreateUser,
  useCssVariableBreakpoint,
  useValidatePassword
} from '@cdab/scania/qpr/hooks'
import type { Language, Market, User } from '@cdab/scania/qpr/schema'
import {
  AuditReminderIntervals,
  EditRights,
  Roles
} from '@cdab/scania/qpr/schema'
import type { TOption } from '@cdab/scania/sdds'
import {
  Breadcrumbs,
  Checkbox,
  Column,
  Container,
  Divider,
  Dropdown,
  DropdownOption,
  EmptyScreen,
  IconWarning,
  Message,
  Row,
  Textfield,
  Toast,
  Toggle
} from '@cdab/scania/sdds'
import { useCallback, useEffect, useState } from 'react'
import {
  isRouteErrorResponse,
  json,
  useLoaderData,
  useNavigate,
  useRouteError
} from 'react-router'

import {
  ValidateEmail,
  capitalizeFirstLetter,
  checkStringLength,
  enumToTranslationText
} from '@cdab/utils'
import { useTranslation } from 'react-i18next'
import { toast } from 'react-toastify'
import {
  ButtonMessageWrapper,
  CheckboxWrapper,
  StyledButton,
  StyledColumn,
  StyledFirstButton,
  StyledRow,
  StyledSectionTitle,
  Title,
  ToggleWrapper
} from './users.styles'

interface UserCreateData
  extends Pick<
    User,
    | 'firstName'
    | 'lastName'
    | 'userName'
    | 'email'
    | 'cellPhone'
    | 'country'
    | 'languageId'
    | 'editRights'
    | 'alertOnCertify'
    | 'auditReminderInterval'
    | 'defaultMarketId'
    | 'truck'
    | 'bus'
    | 'engine'
    | 'active'
    | 'viewId'
  > {
  roleId: number
  markets: number[]
  password: string
}

export type NewAdminUsersLoaderData = {
  languages: Language[]
  allMarkets: Market[]
}

export async function loader(): Promise<NewAdminUsersLoaderData> {
  try {
    const client = getClient()
    const userInfo = await client.GetuserInfo()
    if (!userInfo) {
      throw json({
        status: 401
      })
    }

    const [currentUser, languages, allMarkets] = await Promise.all([
      client.UserService.GetUser(userInfo.userId),
      client.UserService.GetLanguagesForUser(),
      client.MarketsService.GetAllMarkets()
    ])

    if (currentUser.role < Roles.FactoryEngineAdmin) {
      throw json({
        status: 403
      })
    }

    return {
      languages,
      allMarkets
    }
  } catch (error) {
    if (error instanceof Response) {
      throw new Response(null, {
        status: error.status
      })
    } else {
      throw new Response(null, {
        status: 404
      })
    }
  }
}

export function Page() {
  const navigate = useNavigate()
  const { t } = useTranslation(['user', 'common'])
  const { updateTitles } = useTitle()
  const isLg = useCssVariableBreakpoint('--sdds-grid-lg')
  const { languages, allMarkets } = useLoaderData() as NewAdminUsersLoaderData

  const [data, setData] = useState<UserCreateData>({
    userName: '',
    firstName: '',
    lastName: '',
    password: '',
    cellPhone: '',
    country: '',
    defaultMarketId: 0,
    email: '',
    active: true,
    alertOnCertify: false,
    auditReminderInterval: 6,
    viewId: 6,
    languageId: 6,
    truck: false,
    bus: false,
    engine: false,
    roleId: Roles.Auditor,
    editRights: EditRights.ReadOnly,
    markets: [1456] //testmarket
  })

  const [submitState, handleClickCreateUser, setSubmitState] = useCreateUser({
    ...data,
    editRightId: data.editRights
  })

  const [statePassword, handleDoValidatePassword] = useValidatePassword()

  const [stateUserName, setStateUserName] = useState<boolean>(true)
  const [stateFirstName, setStateFirstName] = useState<boolean>(true)
  const [stateLastName, setStateLastName] = useState<boolean>(true)
  const [stateEmail, setStateEmail] = useState<boolean>(true)
  const [stateCellPhone, setStateCellPhone] = useState<boolean>(true)
  const [stateCountry, setStateCountry] = useState<boolean>(true)
  const [stateMarkets, setStateMarkets] = useState<boolean>(true)

  const checkAllData = useCallback(() => {
    return (
      stateUserName &&
      stateFirstName &&
      stateLastName &&
      statePassword.status === 'success' &&
      stateEmail &&
      stateCountry &&
      stateCellPhone &&
      stateMarkets
    )
  }, [
    stateUserName,
    stateFirstName,
    stateLastName,
    statePassword.status,
    stateEmail,
    stateCountry,
    stateCellPhone,
    stateMarkets
  ])

  const validateAllData = useCallback(() => {
    setStateFirstName(checkStringLength(data.firstName, 2))
    setStateLastName(checkStringLength(data.lastName, 2))
    handleDoValidatePassword(data.password)
    setStateEmail(ValidateEmail(data.email))
    setStateCellPhone(checkStringLength(data.cellPhone, 5, true))
    setStateCountry(checkStringLength(data.country, 4, true))
    setStateMarkets(data.markets.length > 0)

    return checkAllData()
  }, [
    checkAllData,
    data.cellPhone,
    data.country,
    data.email,
    data.firstName,
    data.lastName,
    data.markets.length,
    data.password,
    handleDoValidatePassword
  ])

  const onChangeMarkets = useCallback(
    (option: TOption) => {
      const changedMarketId = parseInt(option.value)
      let curMarkets = data.markets
      const marketInData = curMarkets.find(
        marketId => marketId === changedMarketId
      )

      if (marketInData) {
        curMarkets = curMarkets.filter(e => e !== marketInData)
        setData({
          ...data,
          markets: curMarkets.filter(e => e !== marketInData)
        })
        setStateMarkets(curMarkets.length > 0)
        if (changedMarketId === data.defaultMarketId) {
          data.defaultMarketId = 0
        }
      } else {
        curMarkets.push(changedMarketId)
        setData({
          ...data,
          markets: curMarkets
        })
      }
    },
    [data]
  )

  useEffect(() => {
    updateTitles({
      contentHeader: {
        subtitle: t('users', { ns: 'common' }),
        title: t('administration', { ns: 'common' })
      },
      mobile: {
        title: `${t('users', { ns: 'common' })}`,
        description: null
      }
    })
  }, [t])

  useEffect(() => {
    if (submitState.status === 'success') {
      toast(
        <Toast
          type='success'
          headline={t('user-created', { ns: 'common' })}
          subheadline={submitState.message}
        />
      )
      setSubmitState({ status: 'none', message: null })
      navigate('/admin/users/all')
    }
  }, [navigate, submitState.status, submitState.message, setSubmitState, t])

  return (
    <ElasticContentContainer overflowHidden={true}>
      <ScrollableWrapper>
        <Container
          fullHeight
          fluid='normal'
          paddingOnColumns={isLg}
          paddingOnContainer={isLg}
        >
          <Row>
            <Column width={12} className='sdds-u-pt2'>
              <Breadcrumbs
                links={[
                  {
                    text: t('users', { ns: 'common' }),
                    to: `/admin/users/all`
                  },
                  { text: t('new', { ns: 'common' }), to: '' }
                ]}
              />
            </Column>
          </Row>
          <Row>
            <Column width={12} lg={8} offset={{ lg: 2 }}>
              <StyledRow>
                <Column width={12} lg={6} padding={isLg}>
                  <Title>{t('profile')}</Title>
                </Column>
              </StyledRow>
              <StyledRow>
                <StyledColumn width={12} lg={6} padding={isLg}>
                  <Textfield
                    label={t('first-name')}
                    labelPosition='outside'
                    maxLength={50}
                    name='firstName'
                    placeholder={t('first-name')}
                    value={data.firstName}
                    disabled={false}
                    onChange={e => {
                      setData({ ...data, firstName: e.target.value })
                    }}
                    onBlur={e =>
                      setStateFirstName(checkStringLength(e.target.value, 2))
                    }
                    state={!stateFirstName ? 'error' : undefined}
                    helper={
                      !stateFirstName &&
                      t('message.validation-error', { chars: '2' })
                    }
                  />
                </StyledColumn>
                <StyledColumn width={12} lg={6} padding={isLg}>
                  <Textfield
                    label={t('last-name')}
                    labelPosition='outside'
                    maxLength={50}
                    name='lastName'
                    placeholder={t('last-name')}
                    value={data.lastName}
                    disabled={false}
                    onChange={e => {
                      setData({ ...data, lastName: e.target.value })
                    }}
                    onBlur={e =>
                      setStateLastName(checkStringLength(e.target.value, 2))
                    }
                    state={!stateLastName ? 'error' : undefined}
                    helper={
                      !stateLastName &&
                      t('message.validation-error', { chars: '2' })
                    }
                  />
                </StyledColumn>
              </StyledRow>
              <StyledRow>
                <StyledColumn width={12} lg={6} padding={isLg}>
                  <Textfield
                    label={t('user-name')}
                    labelPosition='outside'
                    maxLength={50}
                    name='userName'
                    placeholder={t('user-name')}
                    value={data.userName}
                    disabled={false}
                    onChange={e => {
                      setData({
                        ...data,
                        userName: e.target.value
                      })
                    }}
                    onBlur={e =>
                      setStateUserName(checkStringLength(e.target.value, 3))
                    }
                    state={!stateUserName ? 'error' : undefined}
                    helper={
                      !stateUserName &&
                      t('message.validation-error', { chars: '3' })
                    }
                  />
                </StyledColumn>
                <StyledColumn width={12} lg={6} padding={isLg}>
                  <Textfield
                    label={t('email')}
                    labelPosition='outside'
                    maxLength={50}
                    name='email'
                    placeholder={t('email')}
                    value={data.email}
                    disabled={false}
                    onChange={e => {
                      setData({ ...data, email: e.target.value })
                    }}
                    onBlur={e => setStateEmail(ValidateEmail(e.target.value))}
                    state={!stateEmail ? 'error' : undefined}
                    helper={!stateEmail && t('message.email-validation-error')}
                  />
                </StyledColumn>
              </StyledRow>
              <StyledRow>
                <StyledColumn width={12} lg={6} padding={isLg}>
                  <Textfield
                    label={t('password')}
                    labelPosition='outside'
                    type='password'
                    maxLength={64}
                    name='password'
                    placeholder={t('password')}
                    value={data.password}
                    disabled={false}
                    onChange={e => {
                      setData({ ...data, password: e.target.value })
                    }}
                    onBlur={e => handleDoValidatePassword(e.target.value)}
                    state={
                      statePassword.status === 'error' ? 'error' : undefined
                    }
                    helper={statePassword.message}
                  />
                </StyledColumn>
              </StyledRow>
              <StyledRow>
                <StyledColumn width={12} lg={6} padding={isLg}>
                  <Textfield
                    label={t('mobile-phone')}
                    labelPosition='outside'
                    maxLength={20}
                    name='cellPhone'
                    placeholder={t('mobile-phone')}
                    value={data.cellPhone ?? ''}
                    disabled={false}
                    onChange={e => {
                      setData({ ...data, cellPhone: e.target.value })
                    }}
                    onBlur={e =>
                      setStateCellPhone(
                        checkStringLength(e.target.value, 5, true)
                      )
                    }
                    state={!stateCellPhone ? 'error' : undefined}
                    helper={
                      !stateCellPhone &&
                      t('message.validation-error', { chars: '5' })
                    }
                  />
                </StyledColumn>
                <StyledColumn width={12} lg={6} padding={isLg}>
                  <Textfield
                    label={t('country')}
                    labelPosition='outside'
                    maxLength={40}
                    name='country'
                    placeholder={t('country')}
                    value={data.country ?? ''}
                    disabled={false}
                    onChange={e =>
                      setData({ ...data, country: e.target.value })
                    }
                    onBlur={e =>
                      setStateCountry(
                        checkStringLength(e.target.value, 4, true)
                      )
                    }
                    state={!stateCountry ? 'error' : undefined}
                    helper={
                      !stateCountry &&
                      t('message.validation-error', { chars: '4' })
                    }
                  />
                </StyledColumn>
              </StyledRow>
              <Divider type='light' className='sdds-u-mb1' />
              <StyledRow>
                <Column width={12} lg={6} padding={isLg}>
                  <Title>{t('preferences')}</Title>
                </Column>
              </StyledRow>
              <StyledRow>
                <StyledColumn width={12} lg={6} padding={isLg}>
                  <Dropdown
                    id='editRightsId'
                    label={t('edit-rights')}
                    size='lg'
                    labelPosition='outside'
                    placeholder={t('edit-rights')}
                    defaultOption={data.editRights.toString()}
                    openDirection={isLg ? 'down' : 'auto'}
                    onSelect={(option: TOption) => {
                      setData({
                        ...data,
                        editRights: parseInt(option.value)
                      })
                    }}
                  >
                    {(
                      Object.keys(EditRights) as Array<keyof typeof EditRights>
                    ).map((key, index) => {
                      if (parseInt(key) >= 0) {
                        return (
                          <DropdownOption
                            key={index}
                            value={index.toString()}
                            text={t(
                              enumToTranslationText(EditRights[key].toString())
                            )}
                          />
                        )
                      }

                      return null
                    })}
                  </Dropdown>
                </StyledColumn>
                <StyledColumn width={12} lg={6} padding={isLg}>
                  <Dropdown
                    id='roleId'
                    label={t('role')}
                    size='lg'
                    labelPosition='outside'
                    placeholder={t('role')}
                    defaultOption={data.roleId.toString()}
                    openDirection={isLg ? 'down' : 'auto'}
                    onSelect={(option: TOption) => {
                      setData({
                        ...data,
                        roleId: parseInt(option.value)
                      })
                    }}
                  >
                    {(Object.keys(Roles) as Array<keyof typeof Roles>).map(
                      (key, index) => {
                        if (parseInt(key)) {
                          return (
                            <DropdownOption
                              key={index}
                              value={key.toString()}
                              text={t(
                                enumToTranslationText(Roles[key].toString())
                              )}
                            />
                          )
                        }
                        return null
                      }
                    )}
                  </Dropdown>
                </StyledColumn>
              </StyledRow>
              <StyledRow>
                <StyledColumn width={12} lg={6} padding={isLg}>
                  <Dropdown
                    id='market'
                    label={capitalizeFirstLetter(t('market', { ns: 'common' }))}
                    size='lg'
                    labelPosition='outside'
                    placeholder={capitalizeFirstLetter(
                      t('market', { ns: 'common' })
                    )}
                    defaultOption={data.markets.join(',')}
                    type='multiselect'
                    onSelect={onChangeMarkets}
                    state={!stateMarkets ? 'error' : undefined}
                    openDirection={isLg ? 'down' : 'auto'}
                    helper={
                      !stateMarkets
                        ? t('message.validation-required')
                        : undefined
                    }
                  >
                    {allMarkets
                      .sort((a: Market, b: Market) =>
                        a.marketName.localeCompare(b.marketName)
                      )
                      .map(({ id, marketName }) => {
                        return (
                          <DropdownOption
                            key={id}
                            value={id.toString()}
                            text={marketName}
                          />
                        )
                      })}
                  </Dropdown>
                  <ToggleWrapper>
                    <Toggle
                      checked={data.active ?? false}
                      id='activeToggle'
                      label={t('active')}
                      onChange={() =>
                        setData({
                          ...data,
                          active: !data.active
                        })
                      }
                    />
                  </ToggleWrapper>
                </StyledColumn>
                <StyledColumn width={12} lg={6} padding={isLg}>
                  <StyledSectionTitle>{t('products')}</StyledSectionTitle>
                  <CheckboxWrapper>
                    <Checkbox
                      label={t('bus')}
                      onChange={() =>
                        setData({
                          ...data,
                          bus: !data.bus
                        })
                      }
                      checked={data.bus === true}
                    />
                  </CheckboxWrapper>
                  <CheckboxWrapper>
                    <Checkbox
                      label={t('engine')}
                      onChange={() =>
                        setData({
                          ...data,
                          engine: !data.engine
                        })
                      }
                      checked={data.engine === true}
                    />
                  </CheckboxWrapper>
                  <CheckboxWrapper>
                    <Checkbox
                      label={t('truck')}
                      onChange={() =>
                        setData({
                          ...data,
                          truck: !data.truck
                        })
                      }
                      checked={data.truck === true}
                    />
                  </CheckboxWrapper>
                </StyledColumn>
              </StyledRow>
              <Divider type='light' className='sdds-u-mb1' />
              <StyledRow>
                <Column width={12} lg={6} padding={isLg}>
                  <Title>{t('settings')}</Title>
                </Column>
              </StyledRow>
              <StyledRow>
                <StyledColumn width={12} lg={6} padding={isLg}>
                  <Dropdown
                    id='languageId'
                    label={t('language')}
                    size='lg'
                    labelPosition='outside'
                    placeholder={t('language')}
                    defaultOption={data.languageId.toString()}
                    openDirection={isLg ? 'down' : 'auto'}
                    onSelect={(option: TOption) => {
                      setData({
                        ...data,
                        languageId: parseInt(option.value)
                      })
                    }}
                  >
                    {languages
                      .sort((a, b) => a.name.localeCompare(b.name))
                      .map(language => (
                        <DropdownOption
                          key={language.id}
                          value={language.id.toString()}
                          text={language.name.trim()}
                        />
                      ))}
                  </Dropdown>
                </StyledColumn>
                <StyledColumn width={12} lg={6} padding={isLg}>
                  <Dropdown
                    id='auditReminderInterval'
                    label={t('audit-reminder-interval')}
                    size='lg'
                    labelPosition='outside'
                    placeholder={t('audit-reminder-interval')}
                    defaultOption={
                      data.auditReminderInterval
                        ? data.auditReminderInterval.toString()
                        : 'null'
                    }
                    openDirection={isLg ? 'down' : 'auto'}
                    onSelect={(option: TOption) => {
                      setData({
                        ...data,
                        auditReminderInterval:
                          option.value === 'null'
                            ? null
                            : parseInt(option.value)
                      })
                    }}
                  >
                    {AuditReminderIntervals.map(interval => (
                      <DropdownOption
                        key={interval.id}
                        value={interval.id}
                        text={interval.name}
                      />
                    ))}
                  </Dropdown>
                </StyledColumn>
              </StyledRow>
              <StyledRow>
                <StyledColumn width={12} lg={6} padding={isLg}>
                  <Dropdown
                    id='defaultMarketId'
                    label={t('main-market')}
                    size='lg'
                    labelPosition='outside'
                    placeholder={t('main-market')}
                    defaultOption={data.defaultMarketId.toString()}
                    openDirection={isLg ? 'down' : 'auto'}
                    onSelect={(option: TOption) => {
                      setData({
                        ...data,
                        defaultMarketId: parseInt(option.value)
                      })
                    }}
                    selectedOption={data.defaultMarketId.toString()}
                  >
                    {allMarkets
                      .filter(m => data.markets.includes(m.id))
                      .map(market => (
                        <DropdownOption
                          key={market.id}
                          value={market.id.toString()}
                          text={market.marketName}
                        />
                      ))}
                  </Dropdown>
                </StyledColumn>
                <StyledColumn width={12} lg={6} padding={isLg}>
                  <ToggleWrapper>
                    <Toggle
                      checked={data.alertOnCertify ?? false}
                      id='alertOnCertifyToggle'
                      label={t('alert-on-certify')}
                      onChange={() =>
                        setData({
                          ...data,
                          alertOnCertify: !data.alertOnCertify
                        })
                      }
                    />
                  </ToggleWrapper>
                </StyledColumn>
              </StyledRow>
              <StyledRow>
                <Column width={12} lg={12} padding={isLg}>
                  {submitState.status === 'error' && (
                    <Message
                      className='sdds-u-mt1'
                      variant='single-line'
                      singleLineMessage={submitState.message}
                      type={submitState.status}
                    />
                  )}
                </Column>
              </StyledRow>
              <StyledRow>
                <Column width={12} lg={12} padding={isLg}>
                  <ButtonMessageWrapper>
                    <StyledFirstButton
                      text={t('cancel', {
                        ns: 'common'
                      })}
                      type='secondary'
                      onClick={() => navigate('/admin/users/all')}
                    />
                    <StyledButton
                      disabled={!checkAllData()}
                      text={capitalizeFirstLetter(t('save'))}
                      onClick={() => {
                        validateAllData() && handleClickCreateUser()
                      }}
                    />
                  </ButtonMessageWrapper>
                </Column>
              </StyledRow>
            </Column>
          </Row>
        </Container>
      </ScrollableWrapper>
    </ElasticContentContainer>
  )
}

export function ErrorBoundary() {
  const { t } = useTranslation('errors')
  const isLg = useCssVariableBreakpoint('--sdds-grid-lg')
  const error = useRouteError()

  if (isRouteErrorResponse(error)) {
    let message = t('talk-to-someone')

    if (error.status === 403) {
      message = t('access-denied')
    }

    return (
      <CenteredContainer
        fluid='normal'
        paddingOnColumns={!isLg}
        paddingOnContainer={!isLg}
      >
        <Row>
          <Column fullHeight width={12} padding={false}>
            <EmptyScreen
              title={t('could-not-load-page')}
              description={message}
              icon={<IconWarning />}
            />
          </Column>
        </Row>
      </CenteredContainer>
    )
  }

  // rethrow to let the parent error boundary handle it
  // when it's not a special case for this route
  throw error
}
