export {
  Page as AdminNewDocumentPage,
  loader as adminNewDocumentLoader,
  ErrorBoundary as AdminNewDocumentErrorBoundary
} from './new'
export {
  Page as AdminEditDocumentPage,
  loader as adminEditDocumentLoader,
  ErrorBoundary as AdminEditDocumentErrorBoundary
} from './edit'
export {
  Page as AdminDocumentsPage,
  loader as adminDocumentsLoader,
  ErrorBoundary as AdminDocumentsErrorBoundary
} from './documents'
