import { createRoutesFromElements, Navigate, Route } from 'react-router'
import { createBrowserRouter } from 'react-router-dom'

import { NavigateAndRestoreSearchParams } from '@cdab/components'
import { NotFound } from '@cdab/scania/qpr/components'
import { WithTitle } from '@cdab/scania/qpr/contexts/title'

import { PageTemplate } from '../components/page-template'
import { RequireAuth } from '../components/require-auth'
import { WithFeatureFlags } from '../components/with-feature-flags'
import { WithDefaultI18n, WithUserI18n } from '../components/with-i18n'
import WithTheme from '../components/with-theme'
import { WithUser } from '../components/with-user'

import {
  ErrorBoundary as AuditBaseViewErrorBoundary,
  loader as auditBaseViewLoader,
  AuditIdBaseView
} from './audit/auditId'
import { DeviationsPage } from './audit/auditId/deviations/deviations'
import {
  PledgeBaseView,
  loader as pledgeBaseViewLoader
} from './audit/auditId/points/pledge/pledge-base-view'
import {
  PledgeAttachments,
  loader as pledgeAttachmentsLoader
} from './audit/auditId/points/pledge/pledgeId/attachments'
import { Dashboard, loader as dashboardLoader } from './dashboard/dashboard'
import {
  ExpiringCertificates,
  ErrorBoundary as ExpiringCertificatesErrorBoundary
} from './dashboard/expiring-cerificates'
import {
  SystemMessages,
  ErrorBoundary as SystemMessagesErrorBoundary
} from './dashboard/system-messages'

import {
  DealerPage,
  ErrorBoundary as DealerPageErrorBoundary,
  loader as dealerPageLoader
} from './dealer/dealerId'
import { DealersPage, loader as dealersPageLoader } from './dealers'
import {
  ErrorBoundary as FavoriteAuditsErrorBoundary,
  FavoriteAuditsPage,
  loader as favoriteAuditsPageLoader
} from './favorite-audits'
import { LoggedOut } from './logged-out'
import {
  MarketPage,
  ErrorBoundary as MarketPageErrorBoundary,
  loader as marketsPageLoader
} from './marketId'

import { ToastContainer } from '@cdab/scania/sdds'
import { LoggedInBoundary, RootBoundary } from '../components/error-boundaries'
import * as AdminCheckpoints from './admin/checkpoints'
import * as AdminDocuments from './admin/documents'
import * as AdminSystemMessages from './admin/systemMessages'
import * as AdminTranslations from './admin/translations'
import * as AdminUsers from './admin/users'
import {
  AuditPointBaseView,
  loader as auditPointBaseViewLoader
} from './audit/auditId/points/auditPoint/audit-point-base-view'
import {
  Attachments,
  loader as attachmentsLoader
} from './audit/auditId/points/auditPoint/auditPointId/attachments'
import { CheckPointsTab } from './audit/auditId/points/auditPoint/auditPointId/checkpoints'
import { DeviationsTab } from './audit/auditId/points/auditPoint/auditPointId/deviations/deviations'
import { ReferenceDocumentsTab } from './audit/auditId/points/auditPoint/auditPointId/reference-documents'
import { StatusTab } from './audit/auditId/points/auditPoint/auditPointId/status'
import { GeneralTab } from './audit/auditId/points/pledge/pledgeId/general'
import { PointsBaseView } from './audit/auditId/points/points-base-view'
import {
  loader as auditSummaryLoader,
  SummaryPage
} from './audit/auditId/summary'
import {
  ExpiringActionPlans,
  ErrorBoundary as ExpiringActionPlansErrorBoundary
} from './dashboard/expiring-action-plans'
import {
  ErrorBoundary as ProfileErrorBoundary,
  ProfilePage,
  loader as profilePageLoader
} from './profile'
import * as Reports from './reports/'

import { WithDeviation } from '@cdab/scania/qpr/contexts/deviation'
import {
  AuditDeviaionPageEdit,
  AuditDeviationPageNew
} from './audit/auditId/deviations/deviationId'
import { DeviationsFeedback } from './dashboard/deviations-feedback'
import { action as readSystemMessageAction } from './system-messages/messageId/read'

export const createRouter = () =>
  createBrowserRouter(
    createRoutesFromElements(
      <Route element={<WithTheme />}>
        <Route element={<WithDefaultI18n />}>
          <Route errorElement={<RootBoundary />}>
            <Route element={<ToastContainer />}>
              <Route path='/'>
                <Route index element={<Navigate replace to='/dashboard' />} />
                <Route path='/loggedout' element={<LoggedOut />} />
                <Route element={<RequireAuth />}>
                  <Route element={<WithUser />}>
                    <Route element={<WithFeatureFlags />}>
                      <Route element={<WithUserI18n />}>
                        <Route element={<WithTitle />}>
                          <Route element={<PageTemplate />}>
                            <Route errorElement={<LoggedInBoundary />}>
                              <Route path='*' element={<NotFound />} />

                              <Route
                                id='dashboard'
                                path='dashboard'
                                element={<Dashboard />}
                                loader={dashboardLoader}
                              >
                                <Route
                                  index
                                  element={
                                    <Navigate replace to='system-messages' />
                                  }
                                />
                                <Route
                                  index
                                  path='system-messages'
                                  errorElement={<SystemMessagesErrorBoundary />}
                                  element={<SystemMessages />}
                                />
                                <Route
                                  path='system-messages/:messageId/read'
                                  action={readSystemMessageAction}
                                />
                                <Route
                                  path='expiring-certificates'
                                  errorElement={
                                    <ExpiringCertificatesErrorBoundary />
                                  }
                                  element={<ExpiringCertificates />}
                                />
                                <Route
                                  errorElement={
                                    <ExpiringActionPlansErrorBoundary />
                                  }
                                  path='expiring-action-plans'
                                  element={<ExpiringActionPlans />}
                                />
                                <Route
                                  path='deviations-feedback'
                                  element={<DeviationsFeedback />}
                                />
                              </Route>

                              <Route
                                path='my-audits'
                                element={<FavoriteAuditsPage />}
                                loader={favoriteAuditsPageLoader}
                                errorElement={<FavoriteAuditsErrorBoundary />}
                              />

                              <Route
                                path='dealers'
                                element={<DealersPage />}
                                loader={dealersPageLoader}
                              />

                              <Route
                                path='dealers/:marketId'
                                element={<MarketPage />}
                                loader={marketsPageLoader}
                                errorElement={<MarketPageErrorBoundary />}
                              />

                              <Route path='dealer'>
                                <Route
                                  index
                                  element={<Navigate replace to='/dealers' />}
                                />
                                <Route
                                  path=':dealerId'
                                  id='dealerId'
                                  element={<DealerPage />}
                                  loader={dealerPageLoader}
                                  errorElement={<DealerPageErrorBoundary />}
                                />
                              </Route>

                              <Route path='audit'>
                                <Route
                                  index
                                  element={<Navigate replace to='/market' />}
                                />
                                <Route
                                  id='audit'
                                  path=':auditId'
                                  loader={auditBaseViewLoader}
                                  element={<AuditIdBaseView />}
                                  errorElement={<AuditBaseViewErrorBoundary />}
                                >
                                  <Route
                                    index
                                    element={<Navigate replace to='summary' />}
                                  />
                                  <Route
                                    path='summary'
                                    element={<SummaryPage />}
                                    loader={auditSummaryLoader}
                                  />
                                  <Route
                                    path='points'
                                    element={<PointsBaseView />}
                                  >
                                    <Route
                                      path='pledge/:pledgeId'
                                      id='pledge'
                                      loader={pledgeBaseViewLoader}
                                      element={<PledgeBaseView />}
                                    >
                                      <Route
                                        index
                                        element={
                                          <NavigateAndRestoreSearchParams
                                            replace
                                            to='general'
                                          />
                                        }
                                      />
                                      <Route
                                        path='general'
                                        element={<GeneralTab />}
                                      />
                                      <Route
                                        path='attachments'
                                        element={<PledgeAttachments />}
                                        loader={pledgeAttachmentsLoader}
                                      />
                                    </Route>
                                    <Route
                                      path='auditpoint/:auditPointId'
                                      id='audit-point'
                                      loader={auditPointBaseViewLoader}
                                      element={<AuditPointBaseView />}
                                    >
                                      <Route
                                        index
                                        element={
                                          <NavigateAndRestoreSearchParams
                                            replace
                                            to='status'
                                          />
                                        }
                                      />
                                      <Route
                                        path='status'
                                        element={<StatusTab />}
                                      />
                                      <Route element={<WithDeviation />}>
                                        <Route
                                          path='deviations'
                                          element={<DeviationsTab />}
                                        >
                                          <Route
                                            path=':deviationId'
                                            element={
                                              <AuditDeviaionPageEdit
                                                oneColumnDetails={true}
                                              />
                                            }
                                          />
                                          <Route
                                            path='new'
                                            element={<AuditDeviationPageNew />}
                                          />
                                        </Route>
                                      </Route>
                                      <Route
                                        path='reference-documents'
                                        element={<ReferenceDocumentsTab />}
                                      />
                                      <Route
                                        path='checkpoints'
                                        element={<CheckPointsTab />}
                                      />
                                      <Route
                                        path='attachments'
                                        element={<Attachments />}
                                        loader={attachmentsLoader}
                                      />
                                    </Route>
                                  </Route>

                                  <Route element={<WithDeviation />}>
                                    <Route
                                      path='deviations'
                                      element={<DeviationsPage />}
                                    >
                                      <Route
                                        path=':deviationId'
                                        element={
                                          <AuditDeviaionPageEdit
                                            oneColumnDetails={false}
                                          />
                                        }
                                      />
                                      <Route
                                        path='new'
                                        element={<AuditDeviationPageNew />}
                                      />
                                    </Route>
                                  </Route>
                                </Route>
                              </Route>
                            </Route>

                            <Route
                              path='profile'
                              element={<ProfilePage />}
                              loader={profilePageLoader}
                              errorElement={<ProfileErrorBoundary />}
                            />

                            <Route path='admin'>
                              <Route path='system-messages'>
                                <Route
                                  index
                                  element={<Navigate replace to='all' />}
                                />
                                <Route
                                  path='all'
                                  element={
                                    <AdminSystemMessages.AdminSystemMessagesPage />
                                  }
                                  loader={
                                    AdminSystemMessages.adminSystemMessagesLoader
                                  }
                                  errorElement={
                                    <AdminSystemMessages.AdminSystemMessagesErrorBoundary />
                                  }
                                />
                                <Route
                                  path='new'
                                  id='new-admin-system-message'
                                  element={
                                    <AdminSystemMessages.AdminNewSystemMessagesPage />
                                  }
                                  loader={
                                    AdminSystemMessages.adminNewSystemMessagesLoader
                                  }
                                  errorElement={
                                    <AdminSystemMessages.AdminNewSystemMessagesErrorBoundary />
                                  }
                                />
                                <Route
                                  path=':messageId'
                                  id='admin-system-message'
                                  element={
                                    <AdminSystemMessages.AdminSystemMessageEditPage />
                                  }
                                  loader={
                                    AdminSystemMessages.adminSystemMessagedEditLoader
                                  }
                                  errorElement={
                                    <AdminSystemMessages.AdminEditSystemMessageErrorBoundary />
                                  }
                                />
                              </Route>
                              <Route path='users'>
                                <Route
                                  index
                                  element={<Navigate replace to='all' />}
                                />
                                <Route
                                  path='new'
                                  id='new-admin-user'
                                  element={<AdminUsers.AdminNewUserPage />}
                                  loader={AdminUsers.adminNewUserLoader}
                                  errorElement={
                                    <AdminUsers.AdminNewUserErrorBoundary />
                                  }
                                />
                                <Route
                                  path=':userId'
                                  id='admin-user'
                                  element={<AdminUsers.AdminEditUserPage />}
                                  loader={AdminUsers.adminEditUserLoader}
                                  errorElement={
                                    <AdminUsers.AdminEditUserErrorBoundary />
                                  }
                                />
                                <Route
                                  path='all'
                                  element={<AdminUsers.AdminUsersPage />}
                                  loader={AdminUsers.adminUsersLoader}
                                  errorElement={
                                    <AdminUsers.AdminUsersErrorBoundary />
                                  }
                                />
                                <Route
                                  path='new'
                                  element={<AdminUsers.AdminNewUserPage />}
                                  errorElement={
                                    <AdminUsers.AdminUsersErrorBoundary />
                                  }
                                />
                              </Route>
                              <Route path='documents'>
                                <Route
                                  index
                                  element={<Navigate replace to='all' />}
                                />
                                <Route
                                  path='all'
                                  element={
                                    <AdminDocuments.AdminDocumentsPage />
                                  }
                                  loader={AdminDocuments.adminDocumentsLoader}
                                  errorElement={
                                    <AdminDocuments.AdminDocumentsErrorBoundary />
                                  }
                                />
                                <Route
                                  path='new'
                                  element={
                                    <AdminDocuments.AdminNewDocumentPage />
                                  }
                                  loader={AdminDocuments.adminNewDocumentLoader}
                                  errorElement={
                                    <AdminUsers.AdminNewUserErrorBoundary />
                                  }
                                />
                                <Route
                                  path=':documentId'
                                  element={
                                    <AdminDocuments.AdminEditDocumentPage />
                                  }
                                  loader={
                                    AdminDocuments.adminEditDocumentLoader
                                  }
                                  errorElement={
                                    <AdminUsers.AdminEditUserErrorBoundary />
                                  }
                                />
                              </Route>
                              <Route path='translations'>
                                <Route
                                  index
                                  element={<Navigate replace to='languages' />}
                                />
                                <Route
                                  path='languages'
                                  element={
                                    <AdminTranslations.AdminTranslationsBasePage />
                                  }
                                  errorElement={
                                    <AdminTranslations.AdminTranslationErrorBoundary />
                                  }
                                  loader={
                                    AdminTranslations.adminTranslationBaseLoader
                                  }
                                />
                                <Route
                                  id='admin-translations-language'
                                  path=':dosVersionId/:languageId'
                                  element={
                                    <AdminTranslations.AdminTranslationsLanguagePage />
                                  }
                                  loader={
                                    AdminTranslations.adminTranslationsLanguageLoader
                                  }
                                  errorElement={
                                    <AdminTranslations.AdminTranslationsLanguageErrorBoundary />
                                  }
                                >
                                  <Route
                                    index
                                    element={<Navigate replace to='pledges' />}
                                  />
                                  <Route
                                    path=':translationsType'
                                    loader={
                                      AdminTranslations.adminTranslationsTabLoader
                                    }
                                    element={
                                      <AdminTranslations.AdminTranslationsTabPage />
                                    }
                                    errorElement={
                                      <AdminTranslations.AdminTranslationsTabErrorBoundary />
                                    }
                                  ></Route>
                                </Route>
                              </Route>
                              <Route path='checkpoints'>
                                <Route
                                  index
                                  element={<Navigate replace to='new' />}
                                />
                                <Route
                                  path='new'
                                  element={
                                    <AdminCheckpoints.AdminNewCheckpointPage />
                                  }
                                  loader={
                                    AdminCheckpoints.adminNewCheckpointLoader
                                  }
                                  errorElement={
                                    <AdminCheckpoints.AdminNewCheckpointErrorBoundary />
                                  }
                                />
                              </Route>
                            </Route>

                            <Route
                              path='reports'
                              id='reports'
                              element={<Reports.ReportsPage />}
                              loader={Reports.reportsLoaderPage}
                              errorElement={
                                <Reports.ReportsPageErrorBoundary />
                              }
                            >
                              <Route
                                index
                                element={<Navigate replace to='area-summary' />}
                              />
                              <Route
                                path=':reportType'
                                loader={Reports.areaMarketReportLoaderPage}
                                element={<Reports.AreaMarketReportPage />}
                                errorElement={
                                  <Reports.AreaMarketReportPageErrorBoundary />
                                }
                              ></Route>
                            </Route>
                          </Route>
                        </Route>
                      </Route>
                    </Route>
                  </Route>
                </Route>
              </Route>
            </Route>
          </Route>
        </Route>
      </Route>
    )
  )
