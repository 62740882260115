import type {
  AuditPoint,
  DeviationValidationSchemaIn,
  FileData,
  UserFile
} from '@cdab/scania/qpr/schema'
import {
  DeviationValidationSchema,
  type Deviation
} from '@cdab/scania/qpr/schema'
import { formatISODate } from '@cdab/utils'
import { zodResolver } from '@hookform/resolvers/zod'
import React, { createContext, useState } from 'react'
import { useForm } from 'react-hook-form'
import { Outlet } from 'react-router'

type DeviationContextType = {
  deviation?: Deviation
  form?: ReturnType<typeof useForm>
  updateDeviation: (
    deviation: Deviation | undefined,
    defaultAuditPoint: AuditPoint | undefined,
    files?: UserFile[],
    filesToDelete?: FileData['id'][]
  ) => void
}

const defaultContext: DeviationContextType = {
  deviation: undefined,
  form: undefined,
  updateDeviation: () => undefined
}

export const DeviationContext =
  createContext<DeviationContextType>(defaultContext)

export function DeviationProvider({ children }: { children: React.ReactNode }) {
  const [deviation, setDeviation] = useState<Deviation | undefined>(
    defaultContext.deviation
  )

  const form = useForm({ resolver: zodResolver(DeviationValidationSchema) })

  const contextValue: DeviationContextType = {
    deviation,
    form,
    updateDeviation
  }

  function updateDeviation(
    deviation: Deviation | undefined,
    defaultAuditPoint: AuditPoint | undefined,
    files?: UserFile[],
    filesToDelete?: FileData['id'][]
  ) {
    setDeviation(deviation)

    let initialValues: Partial<DeviationValidationSchemaIn> = {
      withActionPlan: true,
      auditPointId:
        deviation?.auditPointId?.toString() ?? defaultAuditPoint?.id.toString(),
      auditPointNumber:
        deviation?.auditPointNumber ?? defaultAuditPoint?.auditPointNo,
      files,
      filesToDelete
    }

    if (deviation) {
      const due = deviation.expirationDate
        ? formatISODate(deviation.expirationDate)
        : ''
      const completed = deviation.approvalDate
        ? formatISODate(deviation.approvalDate)
        : ''
      initialValues = {
        ...initialValues,
        withActionPlan: !deviation.deviationWithoutActions,
        deviation: deviation.deviation,
        action: deviation.proposedActions || '',
        assignee: deviation.responsible || '',
        due,
        completed
      }
    }

    form.reset(initialValues)
  }

  return (
    <DeviationContext.Provider value={contextValue}>
      {children}
    </DeviationContext.Provider>
  )
}

export function WithDeviation() {
  return (
    <DeviationProvider>
      <Outlet />
    </DeviationProvider>
  )
}
