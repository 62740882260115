import { WithLineLimit } from '@cdab/headless-components'
import {
  getIconSize,
  IconChevronDown,
  IconInfo,
  IconMinus,
  IconTick
} from '@cdab/scania/sdds'
import styled, { css } from 'styled-components'

import { ArrowType, AuditItemState, AuditItemType } from './audit-item.types'

export function getAuditItemStateColor(state: AuditItemState): string {
  switch (state) {
    case AuditItemState.No:
      return 'var(--sdds-negative)'

    case AuditItemState.Yes:
      return 'var(--sdds-positive)'

    case AuditItemState.NotComplete:
      return 'var(--sdds-warning)'

    default:
      /* Always show a border-left, but set alpha to 0 if it shouldn't be visible.
       * This way, the content will not jump around when showing or hiding the border
       */
      return 'rgba(0,0,0,0)' // Alpha 0
  }
}

interface WithAuditItemType {
  type: AuditItemType
}

function getClassNamesFor(type: AuditItemType): string {
  switch (type) {
    case AuditItemType.Header:
      return 'sdds-headline-05'

    case AuditItemType.MajorSubheader:
      return 'sdds-headline-06'

    case AuditItemType.MinorSubheader:
      return 'sdds-headline-07'

    // eslint-disable-next-line no-fallthrough
    case AuditItemType.Normal:
    default:
      return 'sdds-detail-02'
  }
}

export interface StyledAuditItemProps extends WithAuditItemType {
  state: AuditItemState
  onClick?: (e: React.MouseEvent<HTMLButtonElement>) => void
  fullWidth: boolean
}

export const StyledAuditItem = styled.div<StyledAuditItemProps>`
  display: flex;
  flex-direction: column;
  transition: background-color 0.15s ease-in-out;

  ${({ fullWidth }) => css`
    width: ${fullWidth && '100%'};
  `}

  padding: 0 var(--sdds-spacing-element-16);

  border-left: 8px solid ${({ state }) => getAuditItemStateColor(state)};
  border-bottom: 1px solid var(--sdds-grey-400);

  cursor: ${({ onClick }) => (onClick ? 'pointer' : 'inherit')};
`

const iconSize = 'var(--sdds-spacing-element-12)'
const iconPadding = 'var(--sdds-spacing-element-4)'

/**
 * iconSize + iconPadding should be 16px, but in the DOM it isn't. It's probably something at
 * play here I don't understand. But when adding 6px it gets the correct size 👍
 * Feel free to change this if you understand this better 😊
 */
const iconMagicNumber = '6px'

const iconButtonSize = `calc(${iconSize} + ${iconPadding} + ${iconMagicNumber})`

function getIconButtonBackgroundColor(state: AuditItemState) {
  if (state === AuditItemState.NotSet) return 'white'
  return 'var(--sdds-blue-800)'
}

function getIconButtonHoverBackgroundColor(state: AuditItemState) {
  if (state === AuditItemState.NotSet) return 'white'
  return 'var(--sdds-blue-600)'
}

function getIconButtonFocusBackgroundColor(state: AuditItemState) {
  if (state === AuditItemState.NotSet) return 'white'
  return 'var(--sdds-blue-300)'
}

interface IconButtonProps {
  state: AuditItemState
}

export const IconButton = styled.button.attrs({
  className: 'sdds-btn sdds-btn-secondary sdds-btn-icon'
})<IconButtonProps>`
  &&& {
    display: flex;
    align-items: center;
    justify-content: center;

    height: ${iconButtonSize};
    width: ${iconButtonSize};

    padding: ${iconPadding};
    border-color: var(--sdds-grey-500);
    margin-right: var(--sdds-spacing-element-16);
    margin-top: var(--sdds-spacing-element-12);

    align-self: flex-start;

    color: var(--sdds-grey-50);
    background-color: ${({ state }) => getIconButtonBackgroundColor(state)};
    font-size: ${iconSize};

    :hover {
      background-color: ${({ state }) =>
        getIconButtonHoverBackgroundColor(state)};
    }

    :focus {
      background-color: ${({ state }) =>
        getIconButtonFocusBackgroundColor(state)};
    }
  }

  svg {
    width: 4rem;
    height: 4rem;
  }
`

interface RightSideArrowProps {
  arrowType: ArrowType
}

export const RightSideArrow = styled(IconChevronDown)<RightSideArrowProps>`
  margin-top: var(--sdds-spacing-element-12);
  min-width: ${getIconSize('medium')};
  min-width: ${getIconSize('medium')};
  height: ${getIconSize('medium')};
  height: ${getIconSize('medium')};

  transition: transform 0.15s ease-in-out;
  transform: ${({ arrowType }) => {
    switch (arrowType) {
      case ArrowType.Down:
        return 'rotate(0deg)'

      case ArrowType.Up:
        return 'rotate(180deg)'

      case ArrowType.Right: // fallthrough
      default:
        return 'rotate(-90deg)'
    }
  }};
`

const NoIcon = styled.div`
  width: ${iconSize};
  height: ${iconSize};
`

export function getAuditItemStateIcon(state: AuditItemState): React.ReactNode {
  switch (state) {
    case AuditItemState.No:
      return <IconMinus />

    case AuditItemState.NotSet:
      // If no state, render
      return <NoIcon />

    case AuditItemState.Yes:
      return <IconTick />

    default:
      // eslint-disable-next-line react/jsx-no-useless-fragment
      return <></>
  }
}

export const ContentWrapper = styled.div`
  display: flex;
  flex-direction: row;
  flex-grow: 1;
  align-items: start;
  margin-top: var(--sdds-spacing-element-16);
  margin-bottom: var(--sdds-spacing-element-16);

  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`

export const PrefixWrapper = styled.span.attrs<WithAuditItemType>(
  ({ type }) => ({
    className: getClassNamesFor(type)
  })
)<WithAuditItemType>`
  min-width: var(--sdds-spacing-element-32);
  margin-right: var(--sdds-spacing-element-8);
  text-align: left;
  align-self: flex-start;
`

export const Content = styled(WithLineLimit).attrs<WithAuditItemType>(
  ({ type }) => ({
    className: getClassNamesFor(type)
  })
)<WithAuditItemType>`
  width: 100%;
`

export const StyledIconInfo = styled(IconInfo)`
  width: ${getIconSize('medium')};
  height: ${getIconSize('medium')};
  margin-right: var(--sdds-spacing-element-8);
`

export const ChildrenWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
`
export const RightItem = styled.div`
  svg {
    margin-top: var(--sdds-spacing-element-12);
    min-width: ${getIconSize('medium')};
    height: ${getIconSize('medium')};
  }
`
